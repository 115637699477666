import { CurrencyAmount, ETHER, JSBI } from '@pancakeswap/sdk'
import { MAX_NT_EXACT, MAX_NT, MIN_BNB } from '../config/constants'

/**
 * Given some token amount, return the max that can be spent of it
 * @param currencyAmount to return max of
 */
export function maxAmountSpend(currencyAmount?: CurrencyAmount): CurrencyAmount | undefined {
  if (!currencyAmount) return undefined
  if (currencyAmount.currency === ETHER) {
    if (JSBI.greaterThan(currencyAmount.raw, MIN_BNB)) {
      return CurrencyAmount.ether(JSBI.subtract(currencyAmount.raw, MIN_BNB))
    }
    return CurrencyAmount.ether(JSBI.BigInt(0))
  }
  if (currencyAmount.currency.symbol === 'NT') {
    if (currencyAmount.toExact() > MAX_NT_EXACT.toString()) {
      return CurrencyAmount.ether(JSBI.subtract(currencyAmount.raw, MAX_NT))
    }
    return CurrencyAmount.ether(JSBI.BigInt(0))
  }
  return currencyAmount
}

export default maxAmountSpend
